import * as React from "react"
import { Link } from "gatsby"

 const MenuIcon = ({mobile}) => (
  <Link to="/contact" className={mobile==='true' ? 'link' : 'm-hide link'}>
    <div className="enquire--icon with--icon">
      Enquire
      <div className="icon">
      </div>
    </div>
  </Link>
)

export default MenuIcon
