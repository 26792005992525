 import * as React from "react"

 const MenuIcon = () => (
  <div className="menu--icon with--icon">
    <div className="icon"></div>
    <span className="m-hide">Menu</span>
  </div>
)

export default MenuIcon
