 import * as React from "react"

 const CloseIcon = () => (
  <div className="close--icon with--icon mr20">
    <div className="icon"></div>
    <span className="m-hide">Close</span>
  </div> 
)

export default CloseIcon
