import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import FooterInformation from "../components/footerInformation"
import {GatsbyImage} from 'gatsby-plugin-image'

const Footer = ({page}) => {

  const data = useStaticQuery(graphql`
      query {
        logos:allDatoCmsFooter {
          edges {
            node {
              footerLogos {
                gatsbyImageData
              }
            }
          }
        }
      }
  `)
  const logos = data.logos?.edges[0].node.footerLogos?.map((logo, index) => {
    return(
      <div className={"pr20 m-pr10 " + ( index !== 0 ? 'pl20 m-pl10' : '') + (index !== data.logos?.edges[0].node.footerLogos.length-1 ? ' br1w' : '')} key={index}>
        <GatsbyImage image={logo?.gatsbyImageData} alt='Footer Logo' className="max-75" /> 
      </div>
    )
  })
  const logoBar = 
  <div className="logo-bar flex">
    {logos}
  </div>
  if(page!=='Contact') {
    var footerInfo = 
    <div className="flex m-wrap bt1w pt20">
      <div className="w-100">
        <div className="max-650">
          <p className="h1 m0 mb40">Make a confidential<br/> enquiry today</p>
          <Link to='/contact' className="button white light">Enquire</Link>
          <div className="spacer m-hide"></div>  
          <div className="spacer"></div>  
          <div className="m-show mb40">
            <FooterInformation />
          </div>
          {logoBar}
          <div className="uppercase small pb20 mp0 mt40">
          Liability limited by a scheme approved under professional standards legislation
          </div>
        </div>
      </div>
      <div className="w-100 max-500 mla flex flex-wrap m-hide">
        <div className="align-self--top w-100">
          <FooterInformation />
        </div>
        <div className="align-self--bottom pb20 m-hide">
          <p className="m0 small">Site by <a className="link ul-link white" rel="noreferrer" href="https://groundcrew.com.au" target="_blank">Groundcrew</a></p>
        </div>
      </div>
    </div>
  } else {
    footerInfo = 
    <div className="flex m-wrap bt1w mt20 m-m0 align-center">
      <div className="w-50 m-100">
        <div className="p20 mp0 m-pt20">
          {logoBar}
        </div>
      </div>
      <div className="w-50 m-100">
        <p className="m0 p20 mp0 m-mt40 m-small">LIABILITY LIMITED BY A SCHEME APPROVED UNDER PROFESSIONAL STANDARDS LEGISLATION</p>
      </div>
    </div>
  }
  return (
  <footer className="p20 bg-dark text-white">
    {footerInfo}
    <div className="bottom-menu flex space-between pt20 bt1w uppercase small m-hide">
      <Link to='/' state={{internal:true}} className="link ul-link white">Home</Link>
      <Link to='/approach' className="link ul-link white">Our Approach</Link>
      <Link to='/services' className="link ul-link white">Our Services</Link>
      <Link to='/resolution-pathways' className="link ul-link white">Resolution Pathways</Link>
      <Link to='/team' className="link ul-link white">Our Team</Link>
      <Link to='/referrals' className="link ul-link white">Referrals</Link>
      <Link to='/blog' className="link ul-link white">Knowledge & Insights</Link>
    </div>
  </footer>
  )
}

export default Footer
