import * as React from "react"

 const CallUs = () => (
  <a href="tel:(07) 3333 2858" className="link m-show">
    <div className="call--icon with--icon">
      <div className="icon"></div>
    </div>
  </a>
)

export default CallUs
