import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "./partials/logo"
import Menu from "./partials/menu"  
import Enquire from "./partials/enquire"  
import CallUs from "./partials/CallUs"  

const Header = ({ siteTitle, page }) => {
  return (
  <header className={"masthead p20 flex space-between align-center bb1 " + (page === 'Home' ?  'homepage transparent' : '')}>
    <div className="menu">
      <Menu />
    </div>
    <div className="logo">
      <Link to="/" state={{internal:true}} className="link flex">
         <Logo />
      </Link>
    </div>
    <div className="enquire flex">
      <Enquire />
      <CallUs />
    </div>
  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
