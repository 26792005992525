import Close from "./partials/close" 
import Back from "./partials/back"  
import React, { useEffect } from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import { gsap } from "gsap"
import _ from 'lodash' 

const Overlay = () =>{
    useEffect(() => { 
        document.querySelector('.menu--icon').addEventListener("click", openMenu );
        document.querySelector('.close--icon').addEventListener("click", closeMenu );
        document.querySelector('.service-link').addEventListener("mouseenter", openSubmenu );
        document.querySelector('.service-link').addEventListener("click", openMobileMenu );
        document.querySelector('.overlay--menu .back--icon').addEventListener("click", closeMobileMenu );
    });
    const openSubmenu = () => {
        var link = document.querySelector('.service-link');
        if(!link.classList.contains('active')) {
            link.classList.add('active');
            var show = gsap.timeline({paused: true});
            show.to(".overlay--divider", {height: '100vh', duration: 1, ease: "power2.inOut"}); 
            show.staggerTo(".sublink", .5, { opacity:1, ease: "power2.inOut"}, 0.05, "-=.75");
            show.play(); 
        }
    }

    const openMobileMenu = (e) => {
        if(window.innerWidth < 768) {
            e.preventDefault();
            var mobile = gsap.timeline({paused: true});
            mobile.to(".main--menu", {opacity: 0, display:'none', duration: .75, ease: "power2.inOut"}); 
            mobile.to(".overlay--menu .close--icon", {opacity: 0, display:'none', duration: .75, ease: "power2.inOut"}, "-=.75"); 
            mobile.to(".secondary--menu", {opacity: 1, display:'inherit', duration: .75, ease: "power2.inOut"}); 
            mobile.to(".back--icon", {opacity: 1, display:'inherit', duration: .75, ease: "power2.inOut"}, "-=.75"); 
            mobile.play();
        }
    }

    const closeMobileMenu = (e) => {
        if(window.innerWidth < 768) {
            var closeMenu = gsap.timeline({paused: true});
            closeMenu.to(".secondary--menu", {opacity: 0, display:'none', duration: .75, ease: "power2.inOut"}); 
            closeMenu.to(".back--icon", {opacity: 0, display:'none', duration: .75, ease: "power2.inOut"}, "-=.75"); 
            closeMenu.to(".main--menu", {opacity: 1, display:'inherit', duration: .75, ease: "power2.inOut"}); 
            closeMenu.to(".overlay--menu .close--icon", {opacity: 1, display:'inherit', duration: .75, ease: "power2.inOut"}, "-=.75"); 
            closeMenu.play();
        }
    }

    const openMenu = () => {
        var open = gsap.timeline({paused: true});
        open.to(".overlay--menu", {height: '100vh', duration: .75, ease: "power2.inOut"});
        open.to(".will-fade", {opacity: 1, duration: .75, delay:.25});
        open.play();
    }

    const closeMenu = () => {
        document.querySelector('.service-link').classList.remove('active');
        var close = gsap.timeline({paused: true});
        close.to(".will-fade", {opacity: 0, duration: .5});
        close.to(".overlay--menu", {height: '0vh', duration: .75});
        close.to(".overlay--divider", {height: 0, duration: .1});
        close.to(".sublink", {opacity: 0, duration: .1});
        close.play();
    }

    const {services} = useStaticQuery(graphql`
        query {
            services:allDatoCmsService(sort: {fields: order}) {
              nodes {
                name
                byline
                image {
                  gatsbyImageData
                }
              }
            }
        }
    `)

    const menuItems = services.nodes.map(({name}, index) => {
        return(
        <Link to={`/services/${_.kebabCase(name)}`} className="link" key={index}>
            <div className="pt10 pb10 bbw1 uppercase sublink">{name}</div>
        </Link>
        )
    })

    return(
        <div className="overlay--menu">
            <div className="h-100 flex flex-wrap">
                <div className="header p20 align-self--top will-fade">
                    <div className="menu flex"> 
                      <Close />
                      <Back />
                    </div>
                </div>
                <div className="overlay--divider"></div>
                <div className="menu-container m-overflow-scroll w-100 flex m-wrap will-fade">
                    <div className="main--menu w-50 align-self--bottom h1 m-100">
                        <div className="p20">
                            <Link className="op-link link" state={{internal:true}} to="/">Home</Link>
                            <Link className="op-link link" to="/approach">Our Approach</Link>
                            <Link className="op-link link service-link" to="/services">Our Services</Link>
                            <Link className="op-link link" to="/resolution-pathways">Resolution Pathways</Link>
                            <Link className="op-link link" to="/team">Our Team</Link>
                            <Link className="op-link link" to="/referrals">Referrals</Link>
                            <Link className="op-link link" to="/blog">Knowledge & Insights</Link>
                            <Link className="op-link link" to="/contact">Contact us</Link>
                        </div>
                    </div>
                    <div className="secondary--menu w-50 m-100 flex align-self--bottom">
                        <div className="p20 w-100 ml20 m-m0">
                            {menuItems}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Overlay