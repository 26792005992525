import * as React from "react" 

const FooterInformation = () => (
  <>
    <p className="uppercase">Contact Us</p>
    <div className="contact-information max-250 mla">
      <p className="phone m0">
        <span className="uppercase small mr20 lh2">T</span> 
        <a className="link sainte" href="tel:(07) 3333 2858">(07) 3333 2858</a>
      </p>
      <p className="email m0">
        <span className="uppercase small mr20 lh2">E</span> 
        <a className="link sainte" href="mailto:admin@lewisandtrovas.com">admin@lewisandtrovas.com</a>
      </p>
      <p className="address m0 flex">
        <span className="uppercase small mr20 lh2">A</span> 
        <a className="link sainte max-200" href="mailto:admin@lewisandtrovas.com">Level 7, 10 Market St Brisbane CBD QLD 4000</a>
      </p>
    </div>  
  </>
)

export default FooterInformation
