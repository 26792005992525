 import * as React from "react"

 const CloseIcon = () => (
  <div className="back--icon with--icon">
    <div className="icon"></div>
    <span className="ml20">Back</span>
  </div> 
)

export default CloseIcon
