import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Overlay from "./overlay"
import Footer from "./footer" 
import "../scss/main.scss"

const Layout = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  useEffect(() => {
    setDocHeight();
    document.documentElement.style.setProperty('--vhf', (window.innerHeight*.01) + 'px');
    window.addEventListener('resize', setDocHeight);
  });
  const setDocHeight = () => {
    document.documentElement.style.setProperty('--vh', (window.innerHeight*.01) + 'px');
  }
  return (
    <>
      <Overlay />
      <Header page={page} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <Footer page={page} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
